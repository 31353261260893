import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { useDidMount } from '../../hooks/customHooks';

export const AuthModal = ({
  showAuthConfirm,
  authorize,
  t
}) => {
  const [isModalVisible, setIsModalVisible] = useState(showAuthConfirm);
  const isMount = useDidMount();

  useEffect(() => {
    if (isMount) setIsModalVisible(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAuthConfirm]);

  const handleClick = () => {
    setIsModalVisible(false);
    authorize();
  };

  return (
    <Modal
      visible={isModalVisible}
      title={null}
      footer={[
        <Button key="reAuthenticate" onClick={handleClick}>{t('reAuthenticate')}</Button>
      ]}
      closable={null}
    >
      <p>{t('errors:sessionExpired')}</p>
    </Modal>
  );
};

AuthModal.defaultProps = {
  showAuthConfirm: false
};

AuthModal.propTypes = {
  showAuthConfirm: PropTypes.bool,
  authorize: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation(['common', 'errors'])(AuthModal);
